import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p className={styles.copyright}>© 2024 - A.idvisor</p>
        <div className={styles.rightSection}>
          <a
            href="https://ratemyideas.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.button}
          >
            Validate an Idea
          </a>
          <a
            href="https://twitter.com/AntoineLevy27"
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.button} ${styles.twitterButton}`}
          >
            <svg className={styles.twitterIcon} fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
            Follow @AntoineLevy27
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;