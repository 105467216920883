import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navContainer}>
        <Link to="/" className={`${styles.logo} logo`}>
          A.idvisor
        </Link>
        <div className={styles.menuIcon} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`${styles.navMenu} ${isOpen ? styles.active : ''}`}>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/chatbot" onClick={toggleMenu}>A.idvisor Bot</Link></li>
          <li><Link to="/office-hours" onClick={toggleMenu}>Office Hours</Link></li>
          <li><Link to="/signin" onClick={toggleMenu}>Sign In</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;