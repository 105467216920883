import React from 'react';
import styles from './Home.module.css';

const Home = ({ openWaitlist }) => {
  return (
    <div className={styles.home}>
      <div className={styles.content}>
        <h1>
          Ensure your start-up's success by making {' '}
          <span className={styles.highlight}>better decisions, faster</span>
        </h1>
      </div>
      
      <div className={styles.features}>
        <div className={styles.feature}>
          <div className={styles.featureContent}>
            <h3>A.idvisor Bot</h3>
            <p>Ask our highly specialized bot for advice on your start-up.</p>
          </div>
          <button onClick={openWaitlist} className={styles.button}>Try A.idvisor Bot</button>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureContent}>
            <h3>Office Hours</h3>
            <p>Let A.idvisor guide the conversation and uncover your next steps.</p>
          </div>
          <button onClick={openWaitlist} className={styles.button}>Join Office Hours</button>
        </div>
      </div>
    </div>
  );
};

export default Home;
