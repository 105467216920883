import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import WaitlistPopup from './components/WaitlistPopup/WaitlistPopup';
import './App.css';

function App() {
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);

  const openWaitlist = () => setIsWaitlistOpen(true);
  const closeWaitlist = () => setIsWaitlistOpen(false);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <main className="main">
          <Routes>
            <Route path="/" element={<Home openWaitlist={openWaitlist} />} />
            <Route path="/chatbot" element={<Navigate to="/" replace />} />
            <Route path="/office-hours" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer openWaitlist={openWaitlist} />
        <WaitlistPopup isOpen={isWaitlistOpen} onClose={closeWaitlist} />
      </div>
    </Router>
  );
}

export default App;