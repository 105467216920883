import React, { useState } from 'react';
import styles from './WaitlistPopup.module.css';

const WaitlistPopup = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the email to your backend
    // For now, we'll just log it and show an alert
    console.log('Email submitted:', email);
    alert(`Thank you for joining the waitlist! We'll contact you at ${email}`);
    setEmail('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <h2>Join our Waitlist</h2>
        <p>We're not quite ready yet, but we'd love to let you know when we are!</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit">Join Waitlist</button>
        </form>
        <button className={styles.closeButton} onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default WaitlistPopup;